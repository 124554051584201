<template>
  <div>
    <vx-card
      :class="
        $atividades.permissoes.includes(8)
          ? 'os overflow-hidden'
          : 'sem-autorizacao overflow-hidden'
      "
      @click="$atividades.permissoes.includes(8) ? openModalReagendar() : ''"
    >
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="p-5"
            >
              <span>
                <vs-icon
                  icon="phone_missed"
                  size="30px"
                  color="#f44336"
                  class="img"
                ></vs-icon>
              </span>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class
            >
              <span style="font-size: 20px" class="py-2">REMARCAR</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>
    <component
      v-bind:is="modalReagendar"
      :key="reRenderModalReagendar"
      :osSelecionado="item"
      :contrato="contrato"
      @update="update()"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import modalOsReagendar from "./../../contrato/components/os/modalOsReagendar"
export default {
  props: {
    item: {
      required: true
    }
  },
  data () {
    return {
      modalReagendar: '',
      reRenderModalReagendar: 0,
      contrato: {}
    }
  },
  methods: {
    async openModalReagendar () {
      await this.$emit('close')
      await this.getContrato()
      this.modalReagendar = await 'modalOsReagendar'
      this.reRenderModalReagendar = await this.reRenderModalReagendar + 1
    },
    async getContrato () {
      try {
        this.contrato = await this.$http.get(
          "getContratoById/" + this.item.id_contrato
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async update () {
      await this.$emit('update')
    }
  },
  components: {
    ...components,
    modalOsReagendar
  }
};
</script>
<style lang="scss" scoped>
.os {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.osConf {
  background-color: #f44336;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
</style>
