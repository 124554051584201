<template>
  <div class="w-full">
    <vx-card
      :class="
        $atividades.permissoes.includes(8)
          ? 'os overflow-hidden'
          : 'sem-autorizacao overflow-hidden'
      "
    >
      <template slot="no-body">
        <div
          class="item-details px-2"
          @click="$atividades.permissoes.includes(8) ? openModal() : ''"
        >
          <div class="my-1">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="p-5"
            >
              <span>
                <vs-icon
                  icon="phone_forwarded"
                  color="success"
                  class="img"
                  size="30px"
                ></vs-icon>
              </span>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class
            >
              <span style="font-size: 20px" class="py-2">CONFIRMAR</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>

    <vs-popup :title="'CONFIRMAR ' + item.nomeCliente" :active.sync="osModal">
      <vs-row
        vs-type="flex"
        vs-align="center"
        id="concluir"
        class="vs-con-loading__container"
        :key="reRender"
      >
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <div class="w-full pt-3">
            <vs-input
              v-model="pessoaConfirmacao"
              class="p-0 m-0 inputx w-full"
              name="pessoaConfirmacao"
              label="Pessoa Confirmação"
              v-validate="'required'"
            />
            <span
              class="text-danger mb-2 text-sm"
              v-show="errors.has('pessoaConfirmacao')"
            >
              {{ $validators.empty }}</span
            >
          </div>
        </vs-col>
        <vs-col vs-w="5" vs-type="flex" vs-justify="center" vs-align="center">
          <div class="w-full p-0">
            <vs-checkbox
              class="px-2 pt-3"
              icon-pack="feather"
              color="success"
              icon="icon-check"
              v-model="confirmacao"
              >Confirmar Visita</vs-checkbox
            >
          </div>
        </vs-col>
        <vs-col
          vs-w="7"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="mt-5"
        >
          <vx-card
            class="osConf overflow-hidden"
            no-shadow
            @click="submitForm()"
          >
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    class="p-2"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <span style="font-size: 20px">CONFIRMAR OS</span>
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      required: true
    }
  },
  data () {
    return {
      osModal: false,
      pessoaConfirmacao: '',
      confirmacao: true,
      reRender: 0
    }
  },
  methods: {
    async Confirmar () {
      try {
        await this.$vs.loading({ container: "#concluir", scale: 0.6 })
        const res = await this.$http.put(`confirmarOs/` + this.item.id, { confirmado: this.confirmacao == 1 ? 1 : 2, pessoa_confirmacao: this.pessoaConfirmacao })
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: 'atualizar',
        //   setor: 'comercial',
        //   ip: window.localStorage.getItem("ip"),
        //   texto: 'Confirmação da OS N°' + res.id
        // }
        // await this.$logger(logData)
        await this.$vs.notify(this.$notify.Success)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$emit("update")
        await this.close()
        await this.$vs.loading.close("#concluir > .con-vs-loading")
      }
    },
    async submitForm () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.Confirmar()
          } else {
            this.$vs.notify({
              title: 'Preencha todos os campos',
              text: 'Os campos não devem ser vazios!',
              color: 'danger'
            })
            reject("error")
          }
        })
      })
    },
    async close () {
      this.pessoaConfirmacao = ""
      this.osModal = await false
    },
    async openModal () {
      this.pessoaConfirmacao = ""
      this.confirmacao = 1
      this.osModal = true
      this.reRender += 1
    }
  }
};
</script>
<style lang="scss" scoped>
.os {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.osConf {
  background-color: #00bba2;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
</style>
