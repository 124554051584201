<template>
  <div>
    <vs-row
      vs-type="flex"
      class="mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col vs-w="4" vs-justify="flex-start" vs-align="flex-start">
        <h4 style="color: #b4aa99">Confirmação das OS.</h4>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="8">
        <vs-input
          color="dark"
          class="mx-1"
          size="small"
          type="text"
          placeholder
          label="Busca"
          v-model="filtro.busca"
        ></vs-input>
        <vs-input
          size="small"
          class="mx-2"
          type="date"
          label="Data"
          v-model="filtro.data"
        ></vs-input>
        <div class="mx-1">
          <label style="color: black; font-size: 13px">Turno</label>
          <br />
          <el-select
            filterable
            clearable
            size="small"
            placeholder="Turno"
            v-model="filtro.turno"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="turno in turnos"
              :value="turno.id"
              :label="turno.nome"
              :key="turno.id"
            ></el-option>
          </el-select>
        </div>
        <el-button
          size="small"
          type="primary"
          :disabled="blockButton"
          class="mx-1"
          plain
          @click="filtrar()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
    <vs-row vs-type="flex" vs-justify="flex-start">
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #000a12">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    NÃO CONFIRMADOS: {{ osLista.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <perfect-scrollbar
            class="scoll-os-confirmacao"
            @ps-y-reach-end="showMoreOsLista()"
          >
            <div id="osLista" class="vs-con-loading__container">
              <div v-if="!osLista.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>

              <div v-for="data in osListaShow" :key="data.id" class="mx-2">
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div>
                          <vs-col vs-w="9">
                            <vs-col
                              class="mt-1"
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <div
                              class="w-full"
                              @click="(iniciarModal = true), (selected = data)"
                            >
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Contrato
                                  <b>
                                    <a
                                      target="_blank"
                                      :href="'/contrato?id=' + data.id_contrato"
                                      style="color: darkblue; cursor: pointer"
                                      >{{ data.id_contrato }}</a
                                    >
                                  </b>
                                  - Serviço N°
                                  <b>{{ data.id }} - {{ data.tipo }}</b>
                                  <b style="color: black"
                                    >&nbsp;/ {{ data.controle }}</b
                                  >
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Endereco:
                                  <b>
                                    {{ data.logradouro }}, {{ data.numero }},
                                    {{ data.nomeBairro }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-md">
                                  Data:
                                  <b>
                                    {{
                                      data.remarcado > 0
                                        ? data.data_remarcacao
                                        : data.data_competencia
                                    }}
                                  </b>
                                  &nbsp;-&nbsp; Horas:
                                  <b>{{ data.horas_exec }}</b>
                                  &nbsp;-&nbsp;Técnicos:
                                  <b>{{ data.tecnicos_exec }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span
                                  class="item-description text-sm"
                                  v-if="data.obs_interna"
                                >
                                  Obs:
                                  <b>
                                    {{
                                      data.obs_interna
                                        ? data.obs_interna
                                        : "Sem Obs"
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-w="3"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <div class="w-full">
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <Badge
                                  v-if="data.hora_marcada"
                                  :text="data.hora_marcada"
                                  color="warning"
                                ></Badge>
                                <Badge
                                  v-else
                                  :text="data.turno"
                                  color="warning"
                                ></Badge>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <Badge
                                  v-if="data.remarcado > 0"
                                  text="REMARCADO"
                                  color="danger"
                                ></Badge>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <Badge
                                  v-if="data.nomeZona != null"
                                  :text="data.nomeZona"
                                  color="orange"
                                ></Badge>
                              </vs-col>
                            </div>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </vs-col>
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #00bba2">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    CONFIRMADOS: {{ osConfirmados.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <perfect-scrollbar
            class="scoll-os-confirmacao"
            @ps-y-reach-end="showMoreOsConfirmados()"
          >
            <div id="osConfirmados" class="vs-con-loading__container">
              <div v-if="!osConfirmados.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>

              <div
                v-for="data in osListaShowConfiramdos"
                :key="data.id"
                class="mx-2"
              >
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div>
                          <vs-col vs-w="9">
                            <vs-col
                              vs-w="12"
                              class="mt-1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <div
                              class="w-full"
                              @click="(iniciarModal = true), (selected = data)"
                            >
                              <span class="item-description text-sm">
                                Contrato
                                <b>
                                  <a
                                    target="_blank"
                                    :href="'/contrato?id=' + data.id_contrato"
                                    style="color: darkblue; cursor: pointer"
                                    >{{ data.id_contrato }}</a
                                  >
                                </b>
                                - Serviço N°
                                <b>{{ data.id }} - {{ data.tipo }}</b>
                                <b style="color: black"
                                  >&nbsp;/ {{ data.controle }}</b
                                >
                              </span>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Pessoa confirmação:
                                  <b>{{ data.pessoa_confirmacao }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Endereco:
                                  <b>
                                    {{ data.logradouro }}, {{ data.numero }},
                                    {{ data.nomeBairro }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-md">
                                  Data:
                                  <b>
                                    {{
                                      data.remarcado > 0
                                        ? data.data_remarcacao
                                        : data.data_competencia
                                    }}
                                  </b>
                                  &nbsp;-&nbsp; Horas:
                                  <b>{{ data.horas_exec }}</b>
                                  &nbsp;-&nbsp;Técnicos:
                                  <b>{{ data.tecnicos_exec }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span
                                  class="item-description text-sm"
                                  v-if="data.obs_interna"
                                >
                                  Obs:
                                  <b>
                                    {{
                                      data.obs_interna
                                        ? data.obs_interna
                                        : "Sem Obs"
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-w="3"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <div class="w-full">
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <Badge
                                  v-if="data.hora_marcada"
                                  :text="data.hora_marcada"
                                  color="warning"
                                ></Badge>
                                <Badge
                                  v-else
                                  :text="data.turno"
                                  color="warning"
                                ></Badge>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <Badge
                                  v-if="data.nomeZona != null"
                                  :text="data.nomeZona"
                                  color="orange"
                                  class="mt-1"
                                ></Badge>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <Badge
                                  class="mt-1"
                                  v-if="data.confirmado == 2"
                                  text="Sem Visita"
                                  color="danger"
                                  size="small"
                                ></Badge>
                              </vs-col>
                            </div>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </vs-col>
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #f44336">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    REMARCADOS: {{ osRemarcados.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <perfect-scrollbar
            class="scoll-os-confirmacao"
            @ps-y-reach-end="showMoreOsRemarcados()"
          >
            <div id="osRemarcados" class="vs-con-loading__container">
              <div v-if="!osRemarcados.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>

              <div
                v-for="data in osListaShowRemarcados"
                :key="data.id"
                class="mx-2"
              >
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div>
                          <vs-col vs-w="9">
                            <vs-col
                              vs-w="12"
                              class="mt-1"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <div
                              class="w-full"
                              @click="(iniciarModal = true), (selected = data)"
                            >
                              <span class="item-description text-sm">
                                Contrato
                                <b>
                                  <a
                                    target="_blank"
                                    :href="'/contrato?id=' + data.id_contrato"
                                    style="color: darkblue; cursor: pointer"
                                    >{{ data.id_contrato }}</a
                                  >
                                </b>
                                - Serviço N°
                                <b>{{ data.id }} - {{ data.tipo }}</b>
                                <b style="color: black"
                                  >&nbsp;/ {{ data.controle }}</b
                                >
                              </span>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Endereco:
                                  <b>
                                    {{ data.logradouro }}, {{ data.numero }},
                                    {{ data.nomeBairro }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-md">
                                  Data:
                                  <b>
                                    {{
                                      data.remarcado > 0
                                        ? data.data_remarcacao
                                        : data.data_competencia
                                    }}
                                  </b>
                                  &nbsp;-&nbsp; Horas:
                                  <b>{{ data.horas_exec }}</b>
                                  &nbsp;-&nbsp;Técnicos:
                                  <b>{{ data.tecnicos_exec }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span
                                  class="item-description text-sm"
                                  v-if="data.obs_interna"
                                >
                                  Obs:
                                  <b>
                                    {{
                                      data.obs_interna
                                        ? data.obs_interna
                                        : "Sem Obs"
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                            </div>
                          </vs-col>
                          <vs-col
                            vs-w="3"
                            vs-type="flex"
                            vs-justify="flex-end"
                            vs-align="flex-end"
                          >
                            <div class="w-full">
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <Badge
                                  v-if="data.hora_marcada"
                                  :text="data.hora_marcada"
                                  color="warning"
                                ></Badge>
                                <Badge
                                  v-else
                                  :text="data.turno"
                                  color="warning"
                                ></Badge>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                              >
                                <Badge
                                  v-if="data.nomeZona != null"
                                  :text="data.nomeZona"
                                  color="orange"
                                ></Badge>
                              </vs-col>
                            </div>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </vs-col>
    </vs-row>
    <vs-popup
      class
      :title="'' + selected.nomeCliente"
      :active.sync="iniciarModal"
    >
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <modalConfirmarOs
            @update="close()"
            :item="selected"
          ></modalConfirmarOs>
        </vs-col>
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <modalReagendarOs
            @close="iniciarModal = false"
            @update="close()"
            :item="selected"
          ></modalReagendarOs>
        </vs-col>
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <modalContactar
            @update="close()"
            :item="selected"
            tipo="os_confirmacao"
          ></modalContactar>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import modalConfirmarOs from "./components/modalConfirmarOs.vue";
import modalReagendarOs from "./components/modalReagendarOs.vue";
import modalContactar from "./components/contactarModal";
import VisualizarCliente from "./../cliente/visualizar.vue";

export default {
  data() {
    return {
      filtro: {
        dataHoje: this.$formartData.dataFiltro(),
        data: null,
        turno: null,
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
      cardClass: "os-card overflow-hidden",
      selected: [],
      iniciarModal: false,
      osLista: [],

      osListaShow: [],
      osListaShowCount: 2,

      osListaShowConfiramdos: [],
      osListaShowConfiramdosCount: 2,

      osListaShowRemarcados: [],
      osListaShowRemarcadosCount: 2,

      osConfirmados: [],
      osRemarcados: [],
      turnos: [
        { id: null, nome: "Nenhum" },
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" },
      ],
      blockButton: false,
    };
  },
  async mounted() {
    await this.getOsLista();
    await this.getosConfirmados();
    await this.getOsRemarcados();
  },
  methods: {
    async getOsLista() {
      await this.$vs.loading({ container: "#osLista", scale: 0.6 });
      try {
        this.osLista = await this.$http.post(`getOsLista`, this.filtro);
        this.osListaShow = this.osLista.slice(0, 10);
        // this.osListaShow = this.osLista;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#osLista > .con-vs-loading");
    },

    async getosConfirmados() {
      await this.$vs.loading({ container: "#osConfirmados", scale: 0.6 });
      try {
        this.osConfirmados = await this.$http.post(
          `getOsConfirmados`,
          this.filtro
        );
        this.osListaShowConfiramdos = this.osConfirmados.slice(0, 10);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#osConfirmados > .con-vs-loading");
    },

    async getOsRemarcados() {
      await this.$vs.loading({ container: "#osRemarcados", scale: 0.6 });
      try {
        this.osRemarcados = await this.$http.post(
          `getOsRemarcados`,
          this.filtro
        );

        this.osListaShowRemarcados = this.osRemarcados.slice(0, 10);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#osRemarcados > .con-vs-loading");
    },

    async showMoreOsLista() {
      if (
        this.osLista.length > 10 &&
        this.osLista.length > this.osListaShow.length
      ) {
        this.$vs.loading();
        await setTimeout(() => {
          this.osListaShow = this.osLista.slice(0, 10 * this.osListaShowCount);
          this.osListaShowCount += 1;
          this.$vs.loading.close();
        }, 200);
      }
    },

    async showMoreOsConfirmados() {
      if (
        this.osConfirmados.length > 10 &&
        this.osConfirmados.length > this.osListaShowConfiramdos.length
      ) {
        this.$vs.loading();
        await setTimeout(() => {
          this.osListaShowConfiramdos = this.osConfirmados.slice(
            0,
            10 * this.osListaShowConfiramdosCount
          );
          this.osListaShowConfiramdosCount += 1;
          this.$vs.loading.close();
        }, 200);
      }
    },

    async showMoreOsRemarcados() {
      if (
        this.osRemarcados.length > 10 &&
        this.osRemarcados.length > this.osListaShowRemarcados.length
      ) {
        this.$vs.loading();
        await setTimeout(() => {
          this.osListaShowRemarcados = this.osRemarcados.slice(
            0,
            10 * this.osListaShowRemarcadosCount
          );
          this.osListaShowRemarcadosCount += 1;
          this.$vs.loading.close();
        }, 200);
      }
    },

    async filtrar() {
      this.blockButton = true;
      await this.getOsLista();
      await this.getosConfirmados();
      await this.getOsRemarcados();
      this.blockButton = false;
    },
    async closeModalReagendar() {
      this.iniciarModal = await false;
    },
    async close() {
      this.iniciarModal = await false;
      await this.getOsLista();
      await this.getosConfirmados();
      await this.getOsRemarcados();
    },
    async loadingModal() {
      await this.$vs.loading({ container: "#loading", scale: 0.6 });
    },
  },
  components: {
    ...components,
    VisualizarCliente,
    modalConfirmarOs,
    modalReagendarOs,
    modalContactar,
  },
};
</script>
<style scoped lang="scss">
.list-item {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
}
.list-item:hover {
  background: #dcdcdc;
}
.active-list-item {
  background-color: #000a12;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
  color: #ffca00;
}
.os-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scoll-os-confirmacao {
  height: 70vh !important;
}
</style>
